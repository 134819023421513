import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Script from 'react-load-script';
import { Box, Flex, Text } from 'rebass';
import Loader from '../common/components/Loader';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
import Map from './Map';
import PlacesAutocomplete from './PlacesAutocomplete';
import { useFetchLocationsMutation, useMyLocationQuery } from './useLocationsQuery';
import { useMapStore } from './useMapStore';

const MapPage = () => {
  return (
    <WithScript>
      <Content />
    </WithScript>
  );
}

const Content = () => {
  const currentLocation = useMapStore(state => state.currentLocation);
  const setCurrentLocation = useMapStore(state => state.setCurrentLocation);
  const zoom = useMapStore(state => state.zoom);
  const setZoom = useMapStore(state => state.setZoom);
  const setSelectedPlace = useMapStore(state => state.setSelectedPlace);
  const { isLoading: myLocationLoading } = useMyLocationQuery({
    onSuccess: (coords) => {
      const currentLocation = { latitude: coords.latitude, longitude: coords.longitude };
      setCurrentLocation(currentLocation);
      fetchLocations(currentLocation);
    }
  });
  const { mutate: fetchLocations, data: locations, isLoading: locationsLoading } = useFetchLocationsMutation();
  const handleAutocompleteSubmit = (coords) => {
    const currentLocation = { latitude: coords.lat, longitude: coords.lng };
    setSelectedPlace(null);
    setCurrentLocation(currentLocation);
    fetchLocations(currentLocation);
    setZoom(13);
  };


  return (
    <LayoutWithBanner>
      <Box py={[20, 40]}>
        <PlacesAutocomplete onSubmit={handleAutocompleteSubmit} />
        <Flex flexWrap={['wrap', 'nowrap']}>
          {locationsLoading && (
            <Flex width={[1, 300]} order={[2, 1]}>
              <Loader />
            </Flex>
          )}
          <LocationsList locations={locations} />
          <Box width={1} order={[1, 2]} mb={[20, 0]}>
            <Map
              locations={locations}
              center={currentLocation && {
                lat: currentLocation.latitude,
                lng: currentLocation.longitude,
              }}
              zoom={zoom}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Box>
        </Flex>
      </Box>
    </LayoutWithBanner>
  );
};


const LocationsList = ({ locations }) => {
  const setCurrentLocation = useMapStore(state => state.setCurrentLocation);
  const setZoom = useMapStore(state => state.setZoom);
  const selectedPlace = useMapStore(state => state.selectedPlace);

  const containerRef = useRef(null); // Referencja do kontenera listy
  const refs = useRef([]);

  useEffect(() => {
    if (selectedPlace) {
      const selectedIndex = locations?.findIndex(location => location.id === selectedPlace.id);
      if (selectedIndex !== -1 && refs.current[selectedIndex]) {
        const container = containerRef.current;
        const targetElement = refs.current[selectedIndex];

        if (container && targetElement) {
          // Oblicz pozycję w kontekście przewijanego kontenera
          const containerTop = container.getBoundingClientRect().top;
          const targetTop = targetElement.getBoundingClientRect().top;
          const scrollOffset = targetTop - containerTop + container.scrollTop - 10;

          // Przewiń kontener
          container.scrollTo({
            top: scrollOffset,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [selectedPlace, locations]);

  return (
    <Flex
      ref={containerRef} // Referencja do kontenera
      order={[2, 1]}
      flexDirection="column"
      width={[1, !isEmpty(locations) ? 324 : 0]}
      p={1}
      sx={{
        height: 400,
        overflowY: 'scroll', // Zapewnia przewijanie wewnątrz komponentu
      }}
    >
      {locations?.map((location, idx) => (
        <Box
          key={`map-location-place-${idx}`}
          ref={el => (refs.current[idx] = el)} // Przypisz referencję do elementu
          mb={45}
          style={{
            outline: selectedPlace?.id === location?.id && '2px solid #33ff33',
            background: selectedPlace?.id === location?.id && '#f1fef1',
            borderRadius: '8px',
          }}
          p={2}
        >
          <Text>{location.street}</Text>
          <Text mb={10}>{`${location.zip} ${location.city}`}</Text>
          <Text mb="5px" fontSize={14}>
            Godziny otwarcia w tygodniu:
            <br />
            {location.openingHoursWeek}
          </Text>
          {location.openingHoursSaturday && (
            <Text mb="5px" fontSize={14}>
              Godziny otwarcia w soboty:
              <br />
              {location.openingHoursSaturday}
            </Text>
          )}
          {location.openingHoursSunday && (
            <Text mb="5px" fontSize={14}>
              Godziny otwarcia w niedziele:
              <br />
              {location.openingHoursSunday}
            </Text>
          )}
          <Text
            mt={10}
            sx={{ cursor: 'pointer' }}
            color="green"
            onClick={() => {
              setZoom(17);
              setCurrentLocation({
                latitude: location.latitude,
                longitude: location.longitude,
              });
            }}
          >
            Pokaż na mapie
          </Text>
        </Box>
      ))}
    </Flex>
  );
};



const WithScript = (props) => {
  const [state, setState] = useState({ isLoaded: false, error: null });

  return <>
    <Script
      url={process.env.REACT_APP_GOOGLE_MAPS_URL}
      onCreate={() => {
        setState({ isLoaded: false })
      }}
      onError={() => {
        setState({ error: 'Error' })
      }}
      onLoad={() => {
        setState({ isLoaded: true })
      }}
    />
    {state.isLoaded ? props.children : null}
  </>
}

export default MapPage;
