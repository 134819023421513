import React, { useState } from 'react';
import { Flex, Button, Box } from 'rebass';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

const PlacesAutocomplete = ({ onSubmit }) => {
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState(null);

  const handleSelect = async (address) => {
    try {
      const geocode = await geocodeByPlaceId(address.place_id);
      const coords = await getLatLng(geocode[0]);
      setCoordinates(coords);
    } catch (e) {
      console.error(e);
      setCoordinates(null);
    }
  };

  const handleSubmit = () => {
    if (coordinates) onSubmit(coordinates);
  };

  return (
    <Flex mb={37} flexWrap={['wrap', 'nowrap']} >
      <Box flex={[undefined, undefined, 1]} width={"100%"}>
        <GooglePlacesAutocomplete
          placeholder="Wpisz lokalizację"
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          debounce={600}
          autocompletionRequest={{ componentRestrictions: { country: ['pl'] } }}
          inputStyle={{
            width: '100%',
            height: 50,
            marginBottom: 0,
            boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
            border: 0,
            paddingLeft: 20,
          }}
          suggestionsStyles={{
            suggestion: {
              padding: '20px 10px',
              cursor: 'pointer',
            },
            container: {
              position: 'absolute',
              zIndex: 9999,
              background: 'white',
            }
          }}
        />
      </Box>
      <Button
        width={[1, 250]}
        ml={[0, 37]}
        mt={[20, 0]}
        disabled={!coordinates}
        onClick={handleSubmit}
      >
        POKAŻ PUNKTY
      </Button>
    </Flex>
  );
};

export default PlacesAutocomplete;
