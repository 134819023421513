import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import API from '../app/api';
import { API_ROUTES } from '../app/routes';
import { useMapStore } from './useMapStore';

// Fetch current location (geolocation API)
export const useMyLocationQuery = (config) =>
    useQuery(
        ['currentLocation'],
        async () => {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(position.coords),
                    (err) => reject(err.message),
                    { enableHighAccuracy: true, maximumAge: 0 }
                );
            });
        },
        {
            ...config,
        }
    );

// Fetch locations from the API
export const useLocationsQuery = (_location) => {
    const currentLocation = useMapStore((state) => state.currentLocation);
    return useQuery(
        ['locations'],
        async () => {
            const { data } = await API.post(API_ROUTES.PHARMACIES, {
                location: currentLocation,
                radius: 5,
            });
            return data;
        },
        {
            onError: (error) => {
                toast.error(`Error fetching locations: ${error}`);
            },
        }
    );
}

// Fetch locations based on specific latitude and longitude
export const useFetchLocationsMutation = () =>
    useMutation(
        async (location) => {
            const { data } = await API.post(API_ROUTES.PHARMACIES, {
                location,
                radius: 5, // Radius in kilometers
            });
            return data.map(loc => ({
                ...loc,
                id: `${loc.latitude}:${loc.longitude}`
            }))
        },
        {
            onError: (error) => {
                toast.error(`Error fetching locations: ${error.message}`);
            },
        }
    );
