import create from 'zustand';

export const useMapStore = create((set) => ({
    zoom: 13,   
    currentLocation: null,
    selectedPlace: null,
    setZoom: (zoom) => set({ zoom }),
    setCurrentLocation: (location) => set({ currentLocation: location }),
    setSelectedPlace: (place) => set({ selectedPlace: place }),
}));

