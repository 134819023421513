import React from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { useMapStore } from './useMapStore';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const defaultCenter = {
  lat: 51.759445,
  lng: 19.457216,
};

const Map = ({ center, locations = [], zoom = 5.9 }) => {
  const setSelectedPlace = useMapStore(state => state.setSelectedPlace);

  let _zoom = zoom;

  if (!center) {
    _zoom = 5.9;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center ?? defaultCenter}
      zoom={_zoom}
    >
      {locations?.map((location) => {
        return <Marker
          key={location.id}
          position={{ lat: location.latitude, lng: location.longitude }}
          onClick={() => {
            setSelectedPlace({
              ...location,
              clickedTime: new Date().getTime(),
            })
          }}
        />
      })}
    </GoogleMap>
  );
};

export default withGoogleMap(Map);
